import "./Header.scss"
import {useContext, useEffect, useState} from "react"
import {ReactComponent as Logo} from "../assets/logo.svg"
import Network from "../util/Network"
import {useNavigate} from "react-router-dom"
import {ReactComponent as Add} from "../assets/post_add_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {AppContext} from "../App";
import {hash} from "../util/Hash";

interface Props {
    updateSearch: (search: string) => void
}

export default function Header(props:Props) {
    const [search, setSearch] = useState('')
    const [showMenu, setShowMenu] = useState(false)
    const navigate = useNavigate()
    const context = useContext(AppContext)

    useEffect(() => {
        const h = setTimeout(() => props.updateSearch(search), 500)
        return () => clearTimeout(h)
    }, [search, props]);

    function performLogout() {
        Network.logout()
        navigate("/login")
    }

    async function updateLoginAs(role: string) {
        const success = await Network.loginAs(role)
        if (success) {
            await context?.refresh()
        }
        setShowMenu(false)
    }

    return <div className='header'>
        <div className='logo'><Logo/>Kitchen</div>
        <div className='search'>
            <input type='text' defaultValue={search} onChange={e => setSearch(e.target.value)} placeholder='Search...'/>
        </div>
        {context?.currentUser?.role === 'admin' && <div className='add' onClick={() => navigate('/new')}><Add/></div>}
        <div className='profile' onClick={() => setShowMenu(!showMenu)}>
            <img src={context?.currentUser?.image ?? `/avatar-0${hash(context?.currentUser?._id ?? "user") % 5}.png`} alt='user'/>
            <div className={`menu${showMenu ? " show" : ""}`}>
                <div>UID: {context?.currentUser._id}</div>
                {(context?.currentUser?.roles?.indexOf('admin') ?? 0) >= 0 && <>
                    <div onClick={() => updateLoginAs("creator")}>Login as creator</div>
                    <div onClick={() => updateLoginAs('admin')}>Login as admin</div>
                </>}
                <div onClick={performLogout}>Logout</div>
            </div>
        </div>
    </div>
}