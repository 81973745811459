import React, {useContext, useRef, useState} from "react"
import "./Projects.scss"
import Network from "../util/Network"
import {getAssignee, Project, State, Track} from "../util/Workflow"
import {UserBubble} from "./UserBubble"
import {EnumBubble} from "./EnumBubble"
import {ReactComponent as View} from "../assets/visibility_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Attach} from "../assets/attach_file_add_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Forward} from "../assets/arrow_forward_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Back} from "../assets/arrow_back_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Down} from "../assets/arrow_drop_down_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Up} from "../assets/arrow_drop_up_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Refresh} from "../assets/refresh_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Person} from '../assets/person_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {ReactComponent as Copy} from '../assets/content_copy_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {ReactComponent as Comment} from '../assets/comment_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {Step} from "../dialogs/Step"
import {AppContext} from "../App";
import {Attachments} from "../dialogs/Attachments";
import {useNavigate} from "react-router-dom";
import {Assign} from "../dialogs/Assign";
import {Comments} from "../dialogs/Comments";
import {RelativeTime} from "../util/RelativeTime";

interface ProjectsProps {
    projects: Array<Project>
    updateProject: (p: Project) => void
    refresh: () => Promise<void>
}

export function Projects(props : ProjectsProps) {
    const fileRef = useRef<HTMLInputElement>(null)
    const stepRef = useRef<HTMLDialogElement>(null)
    const attachmentsRef = useRef<HTMLDialogElement>(null)
    const assignRef = useRef<HTMLDialogElement>(null)
    const commentRef = useRef<HTMLDialogElement>(null)
    const [currentProj, setCurrentProj] = useState<Project>(props.projects[0])
    const [currentDir, setCurrentDir] = useState<'next'|'prev'>('next')
    const [sort, setSort] = useState("")
    const [refreshing, setRefreshing] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate()

    function view(proj: Project) {
        navigate(`/project/${proj._id}`)
    }

    function attach(proj: Project) {
        setCurrentProj(proj)
        attachmentsRef.current?.showModal()
    }

    function comment(proj: Project) {
        setCurrentProj(proj)
        commentRef.current?.showModal()
    }

    function assign(proj: Project) {
        setCurrentProj(proj)
        assignRef.current?.showModal()
    }

    function getState(proj: Project) {
        return context?.workflow?.get(proj.track)?.states?.get(proj.state)
    }

    function step(proj: Project, dir: 'next'|'prev') {
        setCurrentProj(proj)
        setCurrentDir(dir)
        stepRef.current?.showModal()
    }

    async function refresh() {
        setRefreshing(true)
        await props.refresh()
        setRefreshing(false)
    }

    async function uploadFile() {
        if (!fileRef.current?.files || fileRef.current.files.length < 1 || !currentProj)
            return
        const file = fileRef.current.files[0]
        const url = await Network.uploadFile(currentProj._id, file.name, file.type, await file.arrayBuffer())
        if (url) {
            const proj = await Network.addAttachment(currentProj._id, file.name, url, file.type)
            if (proj)
                updateProject(proj)
        }
    }

    function getAssigneeName(p: Project) {
        const assignee = getAssignee(p, context?.workflow)
        if (assignee) {
            return context?.users.find(u => u._id === assignee)?.name ?? ""
        }
        return ""
    }

    function sortProjects(p: Array<Project>): Array<Project> {
        let f: ((a: Project, b: Project) => number) | undefined = undefined
        switch (sort) {
            case "state": f = (a,b) => Object.values(State).indexOf(a.state) - Object.values(State).indexOf(b.state) ; break
            case "-state": f = (a,b) => Object.values(State).indexOf(b.state) - Object.values(State).indexOf(a.state) ; break
            case "title": f = (a,b) => a.title.localeCompare(b.title); break
            case "-title": f = (a,b) => b.title.localeCompare(a.title); break
            case "assignee": f = (a,b) => getAssigneeName(a).localeCompare(getAssigneeName(b)); break
            case "-assignee": f = (a,b) => getAssigneeName(b).localeCompare(getAssigneeName(a)); break
            case "updated": f = (a, b) => a.updated - b.updated; break
            case "-updated": f = (a, b) => b.updated - a.updated; break
        }
        return f ? [...p].sort(f) : p
    }

    function SortButtons({field}: {field:string}) {
        return <><Up className={sort === field ? "active sort":"sort"} onClick={()=> setSort(field)}/><Down className={sort === `-${field}` ? "active sort":"sort"} onClick={()=> setSort(`-${field}`)}/></>
    }
    function updateProject(proj: Project) {
        props.updateProject(proj)
        if (currentProj._id === proj._id)
            setCurrentProj(proj)
    }

    if (props.projects.length === 0)
        return <div className='empty'>
            It seems there are no matching projects yet.
            {context?.currentUser?.role === 'admin' && <><br/>Maybe you should <span className='link' onClick={() => navigate('/new')}>Start one</span>?</>}
        </div>
    else return <div className='projects'>
        <div className='row h'>
            <div className='track'>TR</div>
            <div className='state'>Status <SortButtons field={"state"}/></div>
            <div className='title'>Title <SortButtons field={"title"}/></div>
            <div className='assignee'>Assignee <SortButtons field={"assignee"}/></div>
            <div className='deadline'>Deadline <SortButtons field={"deadline"}/></div>
            <div className='delivey'>Delivery <SortButtons field={"delivery"}/></div>
            <div className='updated'>Updated <SortButtons field={"updated"}/></div>
            <div className='actions'><Refresh onClick={refresh} className={refreshing ? "animate" : ""}/></div>
        </div>
        {sortProjects(props.projects).map(p => {
            const assignee = getAssignee(p, context?.workflow)
            return <div className='row d' onClick={() => view(p)}>
                <div className='track' key={`track${p._id}`}><EnumBubble value={p.track} enum={Track}/></div>
                <div className='state' key={`state${p._id}`}><EnumBubble value={p.state} enum={State}/></div>
                <div className='title' key={`title${p._id}`}>{p.title}</div>
                <div className='assignee' key={`assignee${p._id}`}>{assignee && <UserBubble user={assignee}/>}</div>
                <div className='deadline' key={`deadline${p._id}`}><RelativeTime date={p.deadline}/></div>
                <div className='delivey' key={`delivery${p._id}`}>{new Date(p.delivery).toLocaleString()}</div>
                <div className='updated' key={`updated${p._id}`}>{new Date(p.updated).toLocaleString()}</div>
                <div className='actions' key={`actions${p._id}`} onClick={e => e.stopPropagation()}>
                    <Back className={getState(p)?.prev ? "" : "disabled"} onClick={() => step(p, 'prev')}/>
                    <View onClick={() => view(p)}/>
                    <Attach onClick={() => attach(p)}/>
                    <Person className={assignee === context?.currentUser?._id ? "" : "disabled"} onClick={() => assign(p)}/>
                    {context?.currentUser?.role ==='admin' && <Copy onClick={() => navigate(`/new?from=${p._id}`)}/>}
                    <Comment onClick={() => comment(p)}/>
                    <Forward className={getState(p)?.next ? "" : "disabled"} onClick={() => step(p, 'next')}/>
                </div>
            </div>
        })}
        <input type='file' ref={fileRef} style={{display: 'none'}} onChange={uploadFile}/>
        <Step project={currentProj} dir={currentDir} externalRef={stepRef} updateProject={updateProject}/>
        <Attachments project={currentProj} externalRef={attachmentsRef} updateProject={updateProject}/>
        <Assign project={currentProj} externalRef={assignRef} updateProject={updateProject}/>
        <Comments project={currentProj} externalRef={commentRef} updateProject={updateProject}/>
    </div>
}
