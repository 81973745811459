import "./NewProject.scss"
import "./Dialog.scss"
import Network from "../util/Network"
import 'react-quill/dist/quill.snow.css'
import React, {RefObject, useEffect, useState} from "react"
import {Project} from "../util/Workflow"
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {Loader} from "../components/Loader";
import {FileIcon} from "../components/FileIcon";
import {Drop} from "../components/Drop";
import {Editor} from "../components/Editor";
import {useNavigate, useSearchParams} from "react-router-dom";

interface Props {
    updateProject:(proj: Project)=>void
    externalRef: RefObject<HTMLDialogElement>
}

export function NewProject({updateProject, externalRef}: Props) {
    const [title, setTitle] = useState("")
    const [brief, setBrief] = useState("")
    const [attachments, setAttachments] = useState<Array<File>>([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    function close() {
        setTitle("")
        setBrief("")
        setAttachments([])
        navigate('/')
    }

    async function create() {
        if (!title || !brief)
            return
        setLoading(true)
        const proj = await Network.createProject(title, brief)
        if (proj) {
            let updated = proj
            for (let attachment of attachments) {
                const url = await Network.uploadFile(proj._id, attachment.name, attachment.type, await attachment.arrayBuffer())
                if (url) {
                    updated = await Network.addAttachment(proj._id, attachment.name, url, attachment.type) ?? updated
                }
            }

            updateProject(updated)
        }
        setLoading(false)
        close()
    }

    useEffect(() => {
        const from = searchParams.get('from')
        if (from) {
            Network.getProject(from).then(p => {
                if (p) {
                    setTitle(p.title)
                    if (p.briefs.length > 0)
                        setBrief(p.briefs[p.briefs.length-1].text)
                }
            })
        }
    }, [searchParams])

    return <dialog className='new-project dialog' ref={externalRef} onClose={close}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            <div className='prompt'>Create new project</div>
            <div>Title:</div>
            <input className='title' type='text' value={title} onChange={e => setTitle(e.target.value)}/>
            <div>Brief:</div>
            <Editor className='brief' value={brief} onChange={setBrief}/>
            <div>attachments:</div>
            <div className='attachments'>{attachments.map(f => <FileIcon attachment={{text: URL.createObjectURL(f), filename: f.name, contentType: f.type}}/>)}</div>
            <Drop drop={(f)=> setAttachments([...attachments, f])} multiple={true}>Click to upload a file, or drag and drop here</Drop>
            <div className='buttons'>
                <div className='cancel button' onClick={close}>Cancel</div>
                <div className={'create button' + ((title && brief) ? "" : " disabled")} onClick={create}>Create</div>
            </div>
        </div>
        <div className={'loader' + (loading ? " loading" : "")}><Loader/></div>
    </dialog>
}