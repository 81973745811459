import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import Login from "./pages/Login"
import {GoogleOAuthProvider} from "@react-oauth/google"
import {Protected} from "./util/Protected"

const router = createBrowserRouter([
    {path: '/login', element: <Login />},
    {path: '/project/:proj_id', element: <Protected><App /></Protected>},
    {path: '/new', element: <Protected><App new={true}/></Protected>},
    {path: '/', element: <Protected><App /></Protected>},
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId='159139330148-tj6nuscu8frjgda3ig5vbq4q6t48lm74.apps.googleusercontent.com'>
          <RouterProvider router={router}/>
      </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
