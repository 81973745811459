import "./Assign.scss"
import "./Dialog.scss"
import {Project} from "../util/Workflow";
import React, {RefObject, useContext, useState} from "react";
import {Loader} from "../components/Loader";
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {AppContext} from "../App";
import {UsersDropdown} from "../components/UsersDropdown";
import Network from "../util/Network";

interface Props {
    project: Project
    externalRef: RefObject<HTMLDialogElement>
    updateProject: (p:Project) => void
}

export function Assign(props: Props) {
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const [producer, setProducer] = useState<string>()
    const [voice, setVoice] = useState<string>()
    const [writer, setWriter] = useState<string>()

    function close() {
        setProducer(undefined)
        setVoice(undefined)
        setWriter(undefined)
        props.externalRef.current?.close()
    }

    async function assign() {
        setLoading(true)
        const project = await Network.assign(props.project._id, {voice, producer, writer})
        setLoading(false)
        if (project) {
            props.updateProject(project)
            close()
        }
    }

    const assignee = context?.workflow?.get(props.project.track)?.states?.get(props.project.state)?.assignee

    return <dialog className='dialog assign' ref={props.externalRef}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            <div className='project-title'>Project: {props.project?.title}</div>

            {assignee !== 'voice' && assignee !== 'writer' && <div className='assign'>Producer: <UsersDropdown user={producer ?? props.project?.producer} setUser={setProducer}/></div>}
            {assignee !== 'producer' && assignee !== 'writer' && <div className='assign'>Voice: <UsersDropdown user={voice ?? props.project?.voice} setUser={setVoice}/></div>}
            {assignee !== 'producer' && assignee !== 'voice' && <div className='assign'>Writer: <UsersDropdown user={writer ?? props.project?.writer} setUser={setWriter}/></div>}

            <div className='buttons'>
                <div className='cancel button' onClick={close}>Cancel</div>
                <div className={'create button' + ((producer || voice || writer) ? "" : " disabled")} onClick={assign}>Assign</div>
            </div>
        </div>
        <div className={'loader' + (loading ? " loading" : "")}><Loader/></div>
    </dialog>
}