import React from "react"
import ReactQuill from "react-quill"

interface Props {
    className?: string
    value: string
    onChange: (value: string) => void
}

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        ['clean']
    ],
}

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'color', 'background',
        'link', 'image'
    ]


export function Editor(props: Props) {
    return <ReactQuill className={props.className ?? 'editor'} theme='snow' value={props.value} onChange={props.onChange} modules={modules} formats={formats}/>
}