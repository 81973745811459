import React, {RefObject, useContext, useState} from "react"
import "./Step.scss"
import "./Dialog.scss"
import {Field, Project} from "../util/Workflow"
import {UsersDropdown} from "../components/UsersDropdown"
import Network from "../util/Network"
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {Loader} from "../components/Loader";
import {Drop} from "../components/Drop";
import {Editor} from "../components/Editor";
import {AppContext} from "../App";

interface Props {
    project?: Project
    dir?: 'next' | 'prev'
    externalRef: RefObject<HTMLDialogElement>
    updateProject: (p:Project) => void
}

export function Step(props: Props) {
    const [updates, setUpdates] = useState(new Map<Field, any>())
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)

    function close() {
        setUpdates(new Map())
        props.externalRef.current?.close()
    }

    const state = (props.project && context) ? context.workflow.get(props.project.track)?.states.get(props.project.state) :undefined
    const transition = (props.dir === 'prev') ? state?.prev : state?.next

    async function next() {
        if (transition && props.project && props.dir) {
            setLoading(true)
            const u = new Map<Field, any>()
            updates.forEach((v,k) => {
                if ((transition?.fields?.indexOf(k) ?? -1) >= 0 || (transition?.optional?.indexOf(k) ?? -1) >= 0)
                    u.set(k, v)
            })
            const p = await Network.projectStep(props.project._id, props.dir, props.project.state, u)
            if (p) {
                props.updateProject(p)
                close()
            }
            setLoading(false)
        }
    }

    function update(field: Field) {
        return (s: any) => setUpdates(u => new Map(u).set(field, s))
    }

    async function addRecording(file: File) {
        if (props.project) {
            const contents = await file.arrayBuffer()
            setLoading(true)
            const u = await Network.uploadFile(props.project._id, file.name, file.type, contents)
            if (u) {
                update(Field.recordings)(u)
            }
            setLoading(false)
        }
    }

    let enable = true
    const fields = [...(transition?.fields ?? []), ...(transition?.optional ?? [])]
    transition?.fields?.forEach(f => enable &&= updates.has(f))

    return <dialog className='step dialog' ref={props.externalRef}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            <div className='prompt'>{transition?.title}</div>
            <div className='project-title'>Project: {props.project?.title}</div>
            {fields.indexOf(Field.producer) >= 0 && <div className='assign'>Producer: <UsersDropdown
                user={updates.get(Field.producer) ?? props.project?.producer} setUser={update(Field.producer)}/></div>}
            {fields.indexOf(Field.voice) >= 0 &&
                <div className='assign'>Voice: <UsersDropdown user={updates.get(Field.voice) ?? props.project?.voice} setUser={update(Field.voice)}/></div>}
            {fields.indexOf(Field.writer) >= 0 &&
                <div className='assign'>Writer: <UsersDropdown user={updates.get(Field.writer) ?? props.project?.writer} setUser={update(Field.writer)}/></div>}
            {fields.indexOf(Field.scripts) >= 0 && <div className='editor'>Script:
                <Editor value={updates.get(Field.scripts)} onChange={update(Field.scripts)}/>
            </div>}
            {fields.indexOf(Field.notes) >= 0 && <div className='editor'>Notes:
                <Editor value={updates.get(Field.notes)} onChange={update(Field.notes)}/>
            </div>}
            {fields.indexOf(Field.recordings) >= 0 && <div className='recording'>Add recording:
                {updates.has(Field.recordings) ? <div>
                        <audio controls>
                            <source src={updates.get(Field.recordings)}/>
                        </audio>
                    </div> :
                    <Drop drop={addRecording} allowed="audio/">Click to upload a file, or drag and drop here</Drop>}
            </div>}
            {fields.indexOf(Field.feedbacks) >= 0 && <div className='editor'>Feedback: {(transition?.fields?.indexOf(Field.feedbacks) ?? -1) > 0 ? <></>:"(optional)"}
                <Editor value={updates.get(Field.feedbacks)} onChange={update(Field.feedbacks)}/>
            </div>}
            <div className='buttons'>
                <div className='cancel button' onClick={close}>Cancel</div>
                <div className={'create button' + (enable ? "" : " disabled")}
                     onClick={next}>Submit
                </div>
            </div>
        </div>
        <div className={'loader' + (loading ? " loading" : "")}><Loader/></div>
    </dialog>
}