interface TrackDetails {users: Map<string, string>, states: Map<State, StateDetails>}
export type Workflow = Map<String, TrackDetails>

interface Content {
    user: string
    timestamp: number
    text: string
}

export type Attachment = Content & {filename: string, contentType: string}

export interface Project {
    _id: string,
    created: number
    updated: number
    deadline: number
    delivery: number
    state: State
    track: Track
    title: string
    creator: string
    briefs: Array<Content>
    scripts: Array<Content>
    recordings: Array<Content>
    feedbacks: Array<Content>
    notes: Array<Content>
    comments: Array<Content>
    attachments: Array<Attachment>
    writer: string
    voice: string
    producer: string
}

export interface StateDetails {
    assignee: "writer" | "voice" | "producer" | "creative" | "account" | "project" | "creator"
    next: Transition,
    prev?: Transition
}

export enum Field {
    state = "state",
    track = "track",
    title = "title",
    brief = "brief",
    scripts = "scripts",
    recordings = "recordings",
    attachments = "attachments",
    writer = "writer",
    voice = "voice",
    producer = "producer",
    feedbacks = "feedbacks",
    notes = "notes"
}

export enum State {
    New = "New",
    Assignment = "Assignment",
    Scripting = "Scripting",
    Voicing = "Voicing",
    Production = "Production",
    Approval = "Approval",
    Done = "Done"
}
export enum Track {
    SL = "SL"
}

export interface Transition {
    title: string,
    nextState: string,
    fields?: Array<Field>,
    optional?: Array<Field>
}

export function getAssignee(p: Project, wf?: Workflow) {
    const track = wf?.get(p.track)
    const assignee = track?.states?.get(p.state)?.assignee
    if (!assignee)
        return undefined

    switch (assignee) {
        case "writer": return p.writer
        case "producer": return p.producer
        case "voice": return p.voice
        case "creator": return p.creator
        default: return track.users.get(assignee)
    }
}