import "./Comments.scss"
import "./Dialog.scss"
import {Project} from "../util/Workflow";
import React, {RefObject, useContext, useState} from "react";
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {Loader} from "../components/Loader";
import Network from "../util/Network";
import {Editor} from "../components/Editor";
import {UserBubble} from "../components/UserBubble";
import {AppContext} from "../App";
import {RelativeTime} from "../util/RelativeTime";

interface Props {
    project?: Project
    externalRef: RefObject<HTMLDialogElement>
    updateProject: (p:Project) => void
}

export function Comments(props: Props) {
    const [loading, setLoading] = useState(false)
    const [newComment, setNewComment] = useState<string>("")
    const context = useContext(AppContext)
    
    function close() {
        setNewComment("")
        props.externalRef.current?.close()
    }
    
    async function comment() {
        if (newComment !== "" && props.project) {
            setLoading(true)
            const project = await Network.comment(props.project._id, newComment)
            setLoading(false)
            if (project) {
                setNewComment("")
                props.updateProject(project)
            }
        }
    }

    function getUser(uid: string) {
        return context?.users?.find(u => u._id === uid)
    }

    return <dialog className='comments dialog' ref={props.externalRef}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            {props.project?.comments && props.project.comments.map((comment, i) => <div className='comment' key={`comment-${i}`}>
                <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: comment.text}}/>
                <div className='details'><UserBubble user={getUser(comment.user)}/><RelativeTime date={comment.timestamp}/></div>
            </div>)}
            <Editor value={newComment} onChange={setNewComment}/>
            <div className='buttons'>
                <div className='cancel button' onClick={close}>Cancel</div>
                <div className={'create button' + (newComment ? "" : " disabled")} onClick={comment}>Comment</div>
            </div>
        </div>
        <div className={'loader' + (loading ? " loading" : "")}><Loader/></div>
    </dialog>
}